<template>
<div>
    <b-row>
        <b-col md="6">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-chart-bar fa-md"></i> <span class="h5"> Estadísticas </span>
                </CCardHeader>
                <CCardBody>
                    <span class="h6 text-muted">ESTADÍSTICA SOBRE REVISIONES DE EQUIPOS DE MEDICIÓN::
                    </span>
                    <hr>
                    <CChartPie :options="optionsEstadistica" class="my-2" :datasets="dataSetsEstadistica" :labels="labelEstadistica" />
                </CCardBody>
            </CCard>
        </b-col>
        <b-col md="6">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-edit fa-md"></i> <span class="h5"> Gestión de Equipos de medición</span>
                </CCardHeader>
                <CCardBody>
                    <b-row class="text-center justify-content-center">
                        <b-col v-if="checkPermissions('009-REC-EQU','c')==1" cols="4" class="my-2">
                            <b-button block size="md" variant="custom" @click="modalRegistrarEquipo = true">
                                <i style="inline-size: auto" class="fas fa-plus-circle fa-3x pb-1"></i><br><span>Nuevo equipo</span>
                            </b-button>
                        </b-col>
                    </b-row>
                </CCardBody>
            </CCard>
        </b-col>
        <b-col md="12">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-list fa-md"></i><span class="h5"> Equipos de medición registradas</span>
                </CCardHeader>
                <CCardBody>
                    <b-row>
                        <b-col md="2">
                            <b-form-group label="Registros por página" class="text-muted">
                                <b-form-select size="xl" v-model="porPagina" :options="paginaOpciones"></b-form-select>
                            </b-form-group>
                        </b-col>
                        <b-col md="7">
                        </b-col>
                        <b-col md="3">
                            <b-form-group label="Busqueda:" class="text-muted">
                                <b-input-group size="xl">
                                    <b-input-group-prepend is-text>
                                        <i class="fas fa-search my-0 mx-0"></i>
                                    </b-input-group-prepend>
                                    <b-form-input type="search" v-model="filter" id="filterInput" placeholder="Buscar..."></b-form-input>
                                </b-input-group>
                            </b-form-group>
                        </b-col>
                        <b-col md="12">
                            <b-table bordered hover show-empty mediun responsive outlined :items="listaEquipos" :fields="camposEquipos" :current-page="paginaActual" :per-page="porPagina" :filter="filter" :filter-included-fields="filterOn" @filtered="onFiltered" empty-text="Aún no hay datos en esta sección." empty-filtered-text="No hay datos que coincidan con tu busqueda.">
                                <template v-slot:cell(index)="data">{{ data.index + 1 }}</template>
                                <template #cell(tipo)="param">
                                    <span v-if="param.item.tipo==1">Vehículo</span>
                                    <span v-else-if="param.item.tipo==2">Maquinaria</span>
                                    <span v-else-if="param.item.tipo==3">Computadora</span>
                                    <span v-else-if="param.item.tipo==4">Otros</span>
                                </template>
                                <template #cell(opciones)="param">
                                    <b-button v-if="checkPermissions('009-REC-EQU','u')==1" @click="abrirModalActualizarEquipo(param)" class="mr-1 mb-1" size="sm" variant="dark" v-c-tooltip="'Ver detalles'">
                                        <i class="fas fa-search my-0 mx-0"></i>
                                    </b-button>
                                    <b-button v-if="checkPermissions('009-REC-EQU','d')==1" @click="eliminarEquipo(param)" class="mr-1 mb-1" size="sm" variant="danger" v-c-tooltip="'Eliminar'">
                                        <i class="fas fa-trash-alt  my-0 mx-0"></i>
                                    </b-button>
                                </template>
                                <template #cell(fechaProximaRevision)="param">
                                    <span class="mr-2">{{param.item.fechaProximaRevision}}</span>
                                    <span class="h5" v-if="validarFecha(param)">
                                        <b-badge variant="danger">!</b-badge>
                                    </span>
                                </template>
                            </b-table>
                        </b-col>
                    </b-row>

                </CCardBody>
                <CCardFooter>
                    <b-pagination size="sm" v-model="paginaActual" :total-rows="filasTotales" :per-page="porPagina" first-number last-number align="right" class="my-0"></b-pagination>
                </CCardfooter>
            </CCard>
        </b-col>
    </b-row>
    <CModal :closeOnBackdrop="false" size="xl" color="primario" :show.sync="modalRegistrarEquipo">
        <template #header>
            <h6 class="modal-title">
                <i class="fas fa-plus-square fa-md"></i>
                <span class="h5"> Nuevo equipo </span>
            </h6>
            <CButtonClose @click="modalRegistrarEquipo = false" class="text-white" />
        </template>
        <validation-observer ref="observer" v-slot="{ handleSubmit }">
            <b-form @submit.stop.prevent="handleSubmit(registrarEquipo)">
                <b-row>
                    <b-col md="3">
                        <validation-provider name="fecha de compra" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Fecha de compra:" class="mb-2">
                                <b-form-input type="date" v-model="datosEquipo.fechaCompra" :state="getValidationState(validationContext)" size="md"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="5">
                        <validation-provider name="identificación" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Identificación:" class="mb-2">
                                <b-form-input type="text" v-model="datosEquipo.identificacion" :state="getValidationState(validationContext)" size="md" placeholder="Ingrese la identificación"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="4">
                        <validation-provider name="ubicación" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Ubicación:" class="mb-2">
                                <b-form-input type="text" v-model="datosEquipo.ubicacion" :state="getValidationState(validationContext)" size="md" placeholder="Ingrese la ubicación"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="4">
                        <validation-provider name="tipo" rules="required" v-slot="{errors}">
                            <b-form-group label="Tipo:" class="mb-2">
                                <v-select :reduce="comboTipo =>comboTipo.idTipo" label="descripcion" placeholder="Seleccione una opción" :class=" {'style-valid-select': datosEquipo.tipo , 'style-invalid-select is-invalid': !!errors.length}" v-model="datosEquipo.tipo" :options="comboTipo" @search:blur="blurTipo">
                                    <span slot="no-options" class="text-muted">Busqueda no encontrada</span>
                                </v-select>
                                <div class="invalid-feedback">{{ errors[0]}}</div>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="4">
                        <validation-provider name="revision" rules="required" v-slot="{errors}">
                            <b-form-group label="Tipo de revisión:" class="mb-2">
                                <v-select :reduce="comboTipoRevision =>comboTipoRevision.idTipoRevision" label="descripcion" placeholder="Seleccione una opción" :class=" {'style-valid-select': datosEquipo.tipoRevision , 'style-invalid-select is-invalid': !!errors.length}" v-model="datosEquipo.tipoRevision" :options="comboTipoRevision" @search:blur="blurTipoRevision">
                                    <span slot="no-options" class="text-muted">Busqueda no encontrada</span>
                                </v-select>
                                <div class="invalid-feedback">{{ errors[0]}}</div>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="2">
                        <validation-provider name="fecha última revisión" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Fecha última revisión:" class="mb-2">
                                <b-form-input type="date" v-model="datosEquipo.fechaUltimaRevision" :state="getValidationState(validationContext)" size="md"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="2">
                        <validation-provider name="fecha próxima revisión" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Fecha próxima revisión:" class="mb-2">
                                <b-form-input type="date" v-model="datosEquipo.fechaProximaRevision" :state="getValidationState(validationContext)" size="md"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="2">
                        <b-form-group label="¿Subcontratada?" class="my-2">
                            <b-form-radio-group small plain v-model="datosEquipo.subcontratada" :options="radioSubcontratado"></b-form-radio-group>
                        </b-form-group>
                    </b-col>
                    <b-col md="2">
                        <b-form-group label="¿Seguro?" class="my-2">
                            <b-form-radio-group small plain v-model="datosEquipo.seguro" :options="radioSeguro"></b-form-radio-group>
                        </b-form-group>
                    </b-col>
                    <b-col md="4">
                        <validation-provider name="responsable" rules="required" v-slot="{errors}">
                            <b-form-group label="Responsable:" class="mb-2">
                                <v-select :reduce="comboCargoResponsable =>comboCargoResponsable.idPuestoTrabajo" label="puestoTrabajo" placeholder="Seleccione una opción" :class=" {'style-valid-select': datosEquipo.idResponsable , 'style-invalid-select is-invalid': !!errors.length}" v-model="datosEquipo.idResponsable" :options="comboCargoResponsable" @search:blur="blurResponsable">
                                    <span slot="no-options" class="text-muted">No hay datos que mostrar. <router-link :to="{name:'Puestos de trabajo'}"> Registra aquí</router-link></span>
                                </v-select>
                                <div class="invalid-feedback">{{ errors[0]}}</div>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="4">
                        <validation-provider name="fecha de vencimiento" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Fecha de vencimiento:" class="mb-2">
                                <b-form-input type="date" v-model="datosEquipo.fechaVencimiento" :state="getValidationState(validationContext)" size="md"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="4">
                        <validation-provider name="certificado de calibración" v-slot="validationContext">
                            <b-form-group label="Certificado de calibración:" class="mb-2">
                                <b-form-file ref="file1" v-model="datosEquipo.certificadoCalibracion" v-on:change="handleFileUpload1" :state="getValidationState(validationContext)" placeholder="Elija un archivo o arrástrelo aquí..." drop-placeholder="Arrastre el documento aquí.." browse-text="Subir"></b-form-file>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="4">
                        <validation-provider name="calendario de manteniniento" v-slot="validationContext">
                            <b-form-group label="Calendario de manteniniento:" class="mb-2">
                                <b-form-file ref="file2" v-model="datosEquipo.calendarioMantenimiento" v-on:change="handleFileUpload2" :state="getValidationState(validationContext)" placeholder="Elija un archivo o arrástrelo aquí..." drop-placeholder="Arrastre el documento aquí.." browse-text="Subir"></b-form-file>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="4">
                        <validation-provider name="inventario de almacenes" v-slot="validationContext">
                            <b-form-group label="Inventario de almacenes:" class="mb-2">
                                <b-form-file ref="file3" v-model="datosEquipo.inventarioAlmacen" v-on:change="handleFileUpload3" :state="getValidationState(validationContext)" placeholder="Elija un archivo o arrástrelo aquí..." drop-placeholder="Arrastre el documento aquí.." browse-text="Subir"></b-form-file>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="12">
                        <validation-provider name="observaciones" :rules="{ }" v-slot="validationContext">
                            <b-form-group label="Observaciones:" class="mb-2">
                                <b-form-textarea v-model="datosEquipo.observaciones" rows="3" max-rows="6" size="md" :state="getValidationState(validationContext)" placeholder="Ingrese las observaciones"></b-form-textarea>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>

                    <b-col class="my-2 text-right" md="12">
                        <b-button :disabled="disabled" size="md" variant="success" class=" mr-2" type="submit">
                            Guardar
                        </b-button>
                        <b-button size="md" variant="danger" @click="modalRegistrarEquipo = false">
                            Cancelar
                        </b-button>
                    </b-col>
                </b-row>
            </b-form>
        </validation-observer>

        <template #footer>
            <span></span>
        </template>
    </CModal>

    <CModal :closeOnBackdrop="false" size="xl" color="primario" :show.sync="modalActualizarEquipo">
        <template #header>
            <h6 class="modal-title"> <i class="fas fa-edit fa-md"></i>
                <span class="h5"> Actualizar equipo</span></h6>
            <CButtonClose @click="modalActualizarEquipo = false" class="text-white" />
        </template>

        <validation-observer ref="observer2" v-slot="{ handleSubmit }">
            <b-form @submit.stop.prevent="handleSubmit(actualizarEquipo)">
                <b-row>
                    <b-col md="3">
                        <validation-provider name="fecha de compra" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Fecha de compra:" class="mb-2">
                                <b-form-input type="date" v-model="datosModificarEquipo.fechaCompra" :state="getValidationState(validationContext)" size="md"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="5">
                        <validation-provider name="identificación" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Identificación:" class="mb-2">
                                <b-form-input type="text" v-model="datosModificarEquipo.identificacion" :state="getValidationState(validationContext)" size="md" placeholder="Ingrese la identificación"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="4">
                        <validation-provider name="ubicación" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Ubicación:" class="mb-2">
                                <b-form-input type="text" v-model="datosModificarEquipo.ubicacion" :state="getValidationState(validationContext)" size="md" placeholder="Ingrese la ubicación"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="4">
                        <validation-provider name="tipo" rules="required" v-slot="{errors}">
                            <b-form-group label="Tipo:" class="mb-2">
                                <v-select :reduce="comboTipo =>comboTipo.idTipo" label="descripcion" placeholder="Seleccione una opción" :class=" {'style-valid-select': datosModificarEquipo.tipo , 'style-invalid-select is-invalid': !!errors.length}" v-model="datosModificarEquipo.tipo" :options="comboTipo" @search:blur="blurActualizarTipo">
                                    <span slot="no-options" class="text-muted">Busqueda no encontrada</span>
                                </v-select>
                                <div class="invalid-feedback">{{ errors[0]}}</div>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="4">
                        <validation-provider name="revision" rules="required" v-slot="{errors}">
                            <b-form-group label="Tipo de revisión:" class="mb-2">
                                <v-select :reduce="comboTipoRevision =>comboTipoRevision.idTipoRevision" label="descripcion" placeholder="Seleccione una opción" :class=" {'style-valid-select': datosModificarEquipo.tipoRevision , 'style-invalid-select is-invalid': !!errors.length}" v-model="datosModificarEquipo.tipoRevision" :options="comboTipoRevision" @search:blur="blurActualizarTipoRevision">
                                    <span slot="no-options" class="text-muted">Busqueda no encontrada</span>
                                </v-select>
                                <div class="invalid-feedback">{{ errors[0]}}</div>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="2">
                        <validation-provider name="fecha última revisión" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Fecha última revisión:" class="mb-2">
                                <b-form-input type="date" v-model="datosModificarEquipo.fechaUltimaRevision" :state="getValidationState(validationContext)" size="md"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="2">
                        <validation-provider name="fecha próxima revisión" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Fecha próxima revisión:" class="mb-2">
                                <b-form-input type="date" v-model="datosModificarEquipo.fechaProximaRevision" :state="getValidationState(validationContext)" size="md"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="2">
                        <b-form-group label="¿Subcontratada?" class="my-2">
                            <b-form-radio-group small plain v-model="datosModificarEquipo.subcontratada" :options="radioSubcontratado"></b-form-radio-group>
                        </b-form-group>
                    </b-col>
                    <b-col md="2">
                        <b-form-group label="¿Seguro?" class="my-2">
                            <b-form-radio-group small plain v-model="datosModificarEquipo.seguro" :options="radioSeguro"></b-form-radio-group>
                        </b-form-group>
                    </b-col>
                    <b-col md="4">
                        <validation-provider name="responsable" rules="required" v-slot="{errors}">
                            <b-form-group label="Responsable:" class="mb-2">
                                <v-select :reduce="comboCargoResponsable =>comboCargoResponsable.idPuestoTrabajo" label="puestoTrabajo" placeholder="Seleccione una opción" :class=" {'style-valid-select': datosModificarEquipo.idResponsable , 'style-invalid-select is-invalid': !!errors.length}" v-model="datosModificarEquipo.idResponsable" :options="comboCargoResponsable" @search:blur="blurActualizarResponsable">
                                    <span slot="no-options" class="text-muted">No hay datos que mostrar. <router-link :to="{name:'Puestos de trabajo'}"> Registra aquí</router-link></span>
                                </v-select>
                                <div class="invalid-feedback">{{ errors[0]}}</div>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="4">
                        <validation-provider name="fecha de vencimiento" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Fecha de vencimiento:" class="mb-2">
                                <b-form-input type="date" v-model="datosModificarEquipo.fechaVencimiento" :state="getValidationState(validationContext)" size="md"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="4">
                        <validation-provider name="certificado de calibración" v-slot="validationContext">
                            <b-form-group label="Certificado de calibración:" class="mb-2">
                                <b-input-group>
                                    <b-input-group-prepend v-if="datosModificarEquipo.urlCertificadoCalibracion != ''">
                                        <b-button variant="dark" class="px-2 py-0" @click="descargarDocumento(datosModificarEquipo.urlCertificadoCalibracion)" v-c-tooltip="'Descargar'">
                                            <i class="fas fa-download fa-xs"></i>
                                        </b-button>
                                    </b-input-group-prepend>
                                    <b-form-file ref="fileActualizar1" v-model="datosModificarEquipo.certificadoCalibracion" :state="(getValidationState(validationContext) )" :placeholder="datosModificarEquipo.nombreCertificadoCalibracion" drop-placeholder="Arrastre el documento aquí.." :accept="accept.toString()" browse-text="Subir" v-on:change="handleFileUploadActualizar3"></b-form-file>
                                </b-input-group>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="4">
                        <validation-provider name="calendario de manteniniento" v-slot="validationContext">
                            <b-form-group label="Calendario de manteniniento:" class="mb-2">
                                <b-input-group>
                                    <b-input-group-prepend v-if="datosModificarEquipo.urlCalendarioMantenimiento != ''">
                                        <b-button variant="dark" class="px-2 py-0" @click="descargarDocumento(datosModificarEquipo.urlCalendarioMantenimiento)" v-c-tooltip="'Descargar'">
                                            <i class="fas fa-download fa-xs"></i>
                                        </b-button>
                                    </b-input-group-prepend>
                                    <b-form-file ref="fileActualizar2" v-model="datosModificarEquipo.calendarioMantenimiento" :state="(getValidationState(validationContext) )" :placeholder="datosModificarEquipo.nombreCalendarioMantenimiento" drop-placeholder="Arrastre el documento aquí.." :accept="accept.toString()" browse-text="Subir" v-on:change="handleFileUploadActualizar2"></b-form-file>
                                </b-input-group>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="4">
                        <validation-provider name="inventario de almacenes" v-slot="validationContext">
                            <b-form-group label="Inventario de almacenes:" class="mb-2">
                                <b-input-group>
                                    <b-input-group-prepend v-if="datosModificarEquipo.urlInventarioAlmacen != ''">
                                        <b-button variant="dark" class="px-2 py-0" @click="descargarDocumento(datosModificarEquipo.urlInventarioAlmacen)" v-c-tooltip="'Descargar'">
                                            <i class="fas fa-download fa-xs"></i>
                                        </b-button>
                                    </b-input-group-prepend>
                                    <b-form-file ref="fileActualizar3" v-model="datosModificarEquipo.inventarioAlmacen" :state="(getValidationState(validationContext) )" :placeholder="datosModificarEquipo.nombreInventarioAlmacen" drop-placeholder="Arrastre el documento aquí.." :accept="accept.toString()" browse-text="Subir" v-on:change="handleFileUploadActualizar3"></b-form-file>
                                </b-input-group>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="12">
                        <validation-provider name="observaciones" :rules="{ }" v-slot="validationContext">
                            <b-form-group label="Observaciones:" class="mb-2">
                                <b-form-textarea v-model="datosModificarEquipo.observaciones" rows="3" max-rows="6" size="md" :state="getValidationState(validationContext)" placeholder="Ingrese las observaciones"></b-form-textarea>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>

                    <b-col class="my-2 text-right" md="12">
                        <b-button :disabled="disabled" size="md" variant="success" class=" mr-2" type="submit">
                            Guardar
                        </b-button>
                        <b-button size="md" variant="danger" @click="modalActualizarEquipo = false">
                            Cancelar
                        </b-button>
                    </b-col>
                </b-row>
            </b-form>
        </validation-observer>

        <template #footer>
            <span></span>
        </template>
    </CModal>
</div>
</template>

<script>
import axios from "axios";
import CONSTANTES from "@/Constantes.js";
import {
    CChartPie
} from '@coreui/vue-chartjs'

export default {
    components: {
        CChartPie
    },
    data() {
        return {
            fechaActual: moment().format('YYYY-MM-DD'),
            disabled: false,
            labelEstadistica: ["En plazo", "Fuera de plazo"],
            optionsEstadistica: {
                legend: {
                    labels: {
                        fontSize: 15,
                    },
                    position: 'left',
                    align: 'center'
                },
            },
            datosEquipo: {
                idCliente: '',
                fechaCompra: moment().format('YYYY-MM-DD'),
                identificacion: '',
                ubicacion: '',
                tipo: '',
                tipoEquipo: 2,
                tipoRevision: '',
                fechaUltimaRevision: moment().format('YYYY-MM-DD'),
                fechaProximaRevision: moment().format('YYYY-MM-DD'),
                subcontratada: 1,
                seguro: 1,
                idResponsable: null,
                fechaVencimiento: moment().format('YYYY-MM-DD'),
                certificadoCalibracion: null,
                calendarioMantenimiento: null,
                inventarioAlmacen: null,
                observaciones: '',
            },
            datosModificarEquipo: {
                idEquipo: '',
                fechaCompra: '',
                identificacion: '',
                ubicacion: '',
                tipo: '',
                tipoRevision: '',
                tipoEquipo: 2,
                fechaUltimaRevision: '',
                fechaProximaRevision: '',
                subcontratada: 1,
                seguro: 1,
                idResponsable: '',
                fechaVencimiento: '',
                certificadoCalibracion: null,
                calendarioMantenimiento: null,
                inventarioAlmacen: null,
                observaciones: '',
                nombreCertificadoCalibracion: '',
                nombreCalendarioMantenimiento: '',
                nombreInventarioAlmacen: '',
                urlCertificadoCalibracion: '',
                urlCalendarioMantenimiento: '',
                urlInventarioAlmacen: '',
            },
            datosSession: {
                idCliente: ''
            },
            listaEquipos: [],
            camposEquipos: [{
                    key: "index",
                    label: "N°",
                    class: "text-center",
                },
                {
                    key: "identificacion",
                    label: "Identificacion",
                    class: "text-center",
                },
                {
                    key: "tipo",
                    label: "Tipo",
                    class: "text-center",
                },
                {
                    key: "fechaProximaRevision",
                    label: "Próxima Revisión",
                    class: "text-center",
                },
                {
                    key: "opciones",
                    label: "",
                    class: "text-center",
                }
            ],

            filasTotales: 1,
            paginaActual: 1,
            porPagina: 10,
            paginaOpciones: [10, 25, 50, 100, {
                value: 9999999,
                text: "Mostrar todos"
            }],

            filter: null,
            filterOn: [],

            modalRegistrarEquipo: false,
            modalActualizarEquipo: false,

            radioSubcontratado: [{
                    value: 1,
                    text: "Si"
                },
                {
                    value: 2,
                    text: "No"
                }
            ],
            radioSeguro: [{
                    value: 1,
                    text: "Si"
                },
                {
                    value: 2,
                    text: "No"
                }
            ],
            comboTipo: [{
                    idTipo: 1,
                    descripcion: 'Vehículo'
                },
                {
                    idTipo: 2,
                    descripcion: 'Maquinaria'
                },
                {
                    idTipo: 3,
                    descripcion: 'Computadora'
                },
                {
                    idTipo: 4,
                    descripcion: 'Otros'
                }
            ],
            comboTipoRevision: [{
                    idTipoRevision: 1,
                    descripcion: 'Visual'
                },
                {
                    idTipoRevision: 2,
                    descripcion: 'Prueba'
                },
                {
                    idTipoRevision: 3,
                    descripcion: 'Otro'
                }
            ],
            comboCargoResponsable: [],
            accept: [
                '.vsd', '.vsdx', '.xlsx', '.xls', '.csv',
                '.pdf', '.doc', '.docx', '.ppt', '.pptx',
                '.png', '.jpg', '.jpeg', '.pdf', '.docx',
                'application/msword',
                'application/vnd.ms-excel'
            ]
        }
    },
    methods: {
        checkPermissions(code, crud) {
            return this.$store.state.permissions.filter(x => x.codigo == code)[0][crud]
        },
        onFiltered(itemsFiltrados) {
            this.filasTotales = itemsFiltrados.length
            this.paginaActual = 1
        },
        blurResponsable() {
            this.computedForm.refs.responsable.validate();
        },
        blurActualizarResponsable() {
            this.computedActualizarForm.refs.responsable.validate();
        },
        getValidationState({
            dirty,
            validated,
            valid = null
        }) {
            return dirty || validated ? valid : null;
        },
        descargarDocumento(url) {
            window.open(url)
        },
        validarFecha(param) {
            return moment(param.item.fechaProximaRevision).isBefore(this.fechaActual)
        },
        handleFileUpload1(e) {
            if (!e.target.files[0]) {
                return;
            } else {
                if (!e.target.files[0].name.match(/\.(vsd|vsdx|xlsx|xls|csv|pdf|doc|docx|ppt|pptx|png|jpg|jpeg|pdf|docx)$/i)) {
                    this.swat('error', "Formato de archivo no admitido.");
                    this.$refs["file1"].reset();
                    return;
                }
                if (e.target.files[0].size > 20971520) {
                    this.swat('error', "El tamaño del archivo no debe ser superior a 20MB");
                    this.$refs["file1"].reset();
                    return;
                }

                this.datosEquipo.certificadoCalibracion = this.$refs.file1.files[0];
            }
        },
        handleFileUpload2(e) {
            if (!e.target.files[0]) {
                return;
            } else {
                if (!e.target.files[0].name.match(/\.(vsd|vsdx|xlsx|xls|csv|pdf|doc|docx|ppt|pptx|png|jpg|jpeg|pdf|docx)$/i)) {
                    this.swat('error', "Formato de archivo no admitido.");
                    this.$refs["file2"].reset();
                    return;
                }
                if (e.target.files[0].size > 20971520) {
                    this.swat('error', "El tamaño del archivo no debe ser superior a 20MB");
                    this.$refs["file2"].reset();
                    return;
                }

                this.datosEquipo.calendarioMantenimiento = this.$refs.file2.files[0];
            }
        },
        handleFileUpload3(e) {
            if (!e.target.files[0]) {
                return;
            } else {
                if (!e.target.files[0].name.match(/\.(vsd|vsdx|xlsx|xls|csv|pdf|doc|docx|ppt|pptx|png|jpg|jpeg|pdf|docx)$/i)) {
                    this.swat('error', "Formato de archivo no admitido.");
                    this.$refs["file3"].reset();
                    return;
                }
                if (e.target.files[0].size > 20971520) {
                    this.swat('error', "El tamaño del archivo no debe ser superior a 20MB");
                    this.$refs["file3"].reset();
                    return;
                }

                this.datosEquipo.inventarioAlmacen = this.$refs.file3.files[0];
            }
        },
        handleFileUploadActualizar1(e) {
            if (!e.target.files[0]) {
                return;
            } else {
                if (!e.target.files[0].name.match(/\.(vsd|vsdx|xlsx|xls|csv|pdf|doc|docx|ppt|pptx|png|jpg|jpeg|pdf|docx)$/i)) {
                    this.swat('error', "Formato de archivo no admitido.");
                    this.$refs["fileActualizar1"].reset();
                    return;
                }
                if (e.target.files[0].size > 20971520) {
                    this.swat('error', "El tamaño del archivo no debe ser superior a 20MB");
                    this.$refs["fileActualizar1"].reset();
                    return;
                }

                this.datosModificarEquipo.certificadoCalibracion = this.$refs.fileActualizar1.files[0];
            }
        },
        handleFileUploadActualizar2(e) {
            if (!e.target.files[0]) {
                return;
            } else {
                if (!e.target.files[0].name.match(/\.(vsd|vsdx|xlsx|xls|csv|pdf|doc|docx|ppt|pptx|png|jpg|jpeg|pdf|docx)$/i)) {
                    this.swat('error', "Formato de archivo no admitido.");
                    this.$refs["fileActualizar2"].reset();
                    return;
                }
                if (e.target.files[0].size > 20971520) {
                    this.swat('error', "El tamaño del archivo no debe ser superior a 20MB");
                    this.$refs["fileActualizar2"].reset();
                    return;
                }

                this.datosModificarEquipo.calendarioMantenimiento = this.$refs.fileActualizar2.files[0];
            }
        },
        handleFileUploadActualizar3(e) {
            if (!e.target.files[0]) {
                return;
            } else {
                if (!e.target.files[0].name.match(/\.(vsd|vsdx|xlsx|xls|csv|pdf|doc|docx|ppt|pptx|png|jpg|jpeg|pdf|docx)$/i)) {
                    this.swat('error', "Formato de archivo no admitido.");
                    this.$refs["fileActualizar3"].reset();
                    return;
                }
                if (e.target.files[0].size > 20971520) {
                    this.swat('error', "El tamaño del archivo no debe ser superior a 20MB");
                    this.$refs["fileActualizar3"].reset();
                    return;
                }

                this.datosModificarEquipo.inventarioAlmacen = this.$refs.fileActualizar3.files[0];
            }
        },

        registrarEquipo() {
            let me = this;
            me.disabled = true;

            const formData = new FormData();
            /*---Necesario para ruta de archivo --*/
            formData.append("file1", me.datosEquipo.certificadoCalibracion);
            formData.append("file2", me.datosEquipo.calendarioMantenimiento);
            formData.append("file3", me.datosEquipo.inventarioAlmacen);

            formData.append("folder", 'equipos/medicion');
            /*---Datos del objeto--*/
            formData.append("idCliente", me.datosSession.idCliente);
            formData.append("fechaCompra", me.datosEquipo.fechaCompra);
            formData.append("identificacion", me.datosEquipo.identificacion);
            formData.append("ubicacion", me.datosEquipo.ubicacion);
            formData.append("tipo", me.datosEquipo.tipo);
            formData.append("tipoEquipo", me.datosEquipo.tipoEquipo);
            formData.append("tipoRevision", me.datosEquipo.tipoRevision);
            formData.append("fechaUltimaRevision", me.datosEquipo.fechaUltimaRevision);
            formData.append("fechaProximaRevision", me.datosEquipo.fechaProximaRevision);
            formData.append("subcontratada", me.datosEquipo.subcontratada);
            formData.append("seguro", me.datosEquipo.seguro);
            formData.append("idResponsable", me.datosEquipo.idResponsable);
            formData.append("fechaVencimiento", me.datosEquipo.fechaVencimiento);
            formData.append("observaciones", me.datosEquipo.observaciones);
            axios
                .post(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/registrar-equipo-medicion",
                    formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            Authorization: `Bearer ${localStorage.token}`
                        }
                    }
                )
                .then(function (response) {
                    let color = response.data.resultado == 1 ? "success" : "error";
                    me.swat(color, response.data.mensaje)
                    me.modalRegistrarEquipo = false;
                    me.listarEquiposMedicion();
                    me.disabled = false;
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                    me.disabled = false;

                });
        },
        actualizarEquipo() {
            let me = this;
            me.disabled = true;

            const formData = new FormData();
            /*---Necesario para ruta de archivo --*/
            formData.append("file1", me.datosModificarEquipo.certificadoCalibracion);
            formData.append("file2", me.datosModificarEquipo.calendarioMantenimiento);
            formData.append("file3", me.datosModificarEquipo.inventarioAlmacen);

            formData.append("folder", 'equipos/medicion');
            /*---Datos del objeto--*/
            formData.append("idEquipo", me.datosModificarEquipo.idEquipo);
            formData.append("idCliente", me.datosSession.idCliente);
            formData.append("fechaCompra", me.datosModificarEquipo.fechaCompra);
            formData.append("identificacion", me.datosModificarEquipo.identificacion);
            formData.append("ubicacion", me.datosModificarEquipo.ubicacion);
            formData.append("tipo", me.datosModificarEquipo.tipo);
            formData.append("tipoEquipo", me.datosModificarEquipo.tipoEquipo);
            formData.append("tipoRevision", me.datosModificarEquipo.tipoRevision);
            formData.append("fechaUltimaRevision", me.datosModificarEquipo.fechaUltimaRevision);
            formData.append("fechaProximaRevision", me.datosModificarEquipo.fechaProximaRevision);
            formData.append("subcontratada", me.datosModificarEquipo.subcontratada);
            formData.append("seguro", me.datosModificarEquipo.seguro);
            formData.append("idResponsable", me.datosModificarEquipo.idResponsable);
            formData.append("fechaVencimiento", me.datosModificarEquipo.fechaVencimiento);
            formData.append("observaciones", me.datosModificarEquipo.observaciones);
            formData.append("urlInventarioAlmacen", me.datosModificarEquipo.urlInventarioAlmacen);
            formData.append("urlCertificadoCalibracion", me.datosModificarEquipo.urlCertificadoCalibracion);
            formData.append("urlCalendarioMantenimiento", me.datosModificarEquipo.urlCalendarioMantenimiento);
            formData.append("nombreInventarioAlmacen", me.datosModificarEquipo.nombreInventarioAlmacen);
            formData.append("nombreCertificadoCalibracion", me.datosModificarEquipo.nombreCertificadoCalibracion);
            formData.append("nombreCalendarioMantenimiento", me.datosModificarEquipo.nombreCalendarioMantenimiento);
            axios
                .post(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/registrar-equipo-medicion",
                    formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            Authorization: `Bearer ${localStorage.token}`
                        }
                    }
                )
                .then(function (response) {
                    let color = response.data.resultado == 1 ? "success" : "error";
                    me.swat(color, response.data.mensaje)
                    me.modalActualizarEquipo = false;
                    me.listarEquiposMedicion();
                    me.disabled = false;
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                    me.disabled = false;

                });

        },
        // listarEmpleados() {
        //     let me = this;
        //     axios.defaults.headers.common[
        //         "Authorization"
        //     ] = `Bearer ${localStorage.token}`;
        //     axios
        //         .get(
        //             CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/listar-empleados", {
        //                 params: {
        //                     idCliente: me.datosSession.idCliente
        //                 },
        //             }
        //         )
        //         .then(function (response) {
        //             me.comboResponsable = response.data
        //         })
        //         .catch(function (error) {
        //             me.swat('error', 'Algo salió mal!')
        //         });
        // },
        listarPuestosTrabajo() {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/listar-puestos-trabajo", {
                        params: {
                            idCliente: me.datosSession.idCliente
                        },
                    }
                )
                .then(function (response) {
                    me.comboCargoResponsable = response.data;
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                });
        },
        listarEquiposMedicion() {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/listar-equipos", {
                        params: {
                            idCliente: me.datosSession.idCliente
                        },
                    }
                )
                .then(function (response) {
                    me.listaEquipos = response.data.filter(x => x.tipoEquipo == 2);
                    me.filasTotales = me.listaEquipos.length;
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                });
        },
        abrirModalActualizarEquipo(param) {
            let me = this;
            me.datosModificarEquipo.idEquipo = param.item.idEquipo;
            me.datosModificarEquipo.idCliente = param.item.idCliente;
            me.datosModificarEquipo.fechaCompra = param.item.fechaCompra;
            me.datosModificarEquipo.identificacion = param.item.identificacion;
            me.datosModificarEquipo.ubicacion = param.item.ubicacion;
            me.datosModificarEquipo.tipo = param.item.tipo;
            me.datosModificarEquipo.tipoRevision = param.item.tipoRevision;
            me.datosModificarEquipo.fechaProximaRevision = param.item.fechaProximaRevision;
            me.datosModificarEquipo.fechaUltimaRevision = param.item.fechaUltimaRevision;
            me.datosModificarEquipo.subcontratada = param.item.subcontratada;
            me.datosModificarEquipo.seguro = param.item.seguro;
            me.datosModificarEquipo.idResponsable = param.item.idResponsable;
            me.datosModificarEquipo.fechaVencimiento = param.item.fechaVencimiento;
            me.datosModificarEquipo.certificadoCalibracion = param.item.certificadoCalibracion;
            me.datosModificarEquipo.calendarioMantenimiento = param.item.calendarioMantenimiento;
            me.datosModificarEquipo.observaciones = param.item.observaciones;
            me.datosModificarEquipo.nombreCertificadoCalibracion = param.item.nombreCertificadoCalibracion;
            me.datosModificarEquipo.nombreCalendarioMantenimiento = param.item.nombreCalendarioMantenimiento;
            me.datosModificarEquipo.nombreInventarioAlmacen = param.item.nombreInventarioAlmacen;
            me.datosModificarEquipo.urlCertificadoCalibracion = param.item.urlCertificadoCalibracion;
            me.datosModificarEquipo.urlCalendarioMantenimiento = param.item.urlCalendarioMantenimiento;
            me.datosModificarEquipo.urlInventarioAlmacen = param.item.urlInventarioAlmacen;

            this.modalActualizarEquipo = true;
        },
        resetModalRegistrarEquipo() {
            this.$nextTick(() => {
                this.$refs.observer.reset();
            });
            this.datosEquipo.fechaCompra = moment().format('YYYY-MM-DD'),
                this.datosEquipo.identificacion = '',
                this.datosEquipo.ubicacion = '',
                this.datosEquipo.tipo = '',
                this.datosEquipo.tipoRevision = '',
                this.datosEquipo.fechaUltimaRevision = moment().format('YYYY-MM-DD'),
                this.datosEquipo.fechaProximaRevision = moment().format('YYYY-MM-DD'),
                this.datosEquipo.subcontratada = 1,
                this.datosEquipo.seguro = 1,
                this.datosEquipo.idResponsable = null,
                this.datosEquipo.fechaVencimiento = moment().format('YYYY-MM-DD'),
                this.datosEquipo.certificadoCalibracion = null,
                this.datosEquipo.calendarioMantenimiento = null,
                this.datosEquipo.inventarioAlmacen = null,
                this.datosEquipo.observaciones = ''
        },
        resetModalActualizarEquipo() {
            this.$nextTick(() => {
                this.$refs.observer2.reset();
            });

        },
        eliminarEquipo(param) {
            let me = this
            me.$swal.fire({
                title: '¿Estas seguro de eliminar el equipo?',
                text: "¡No podrás revertir esto!",
                icon: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#d33',
                confirmButtonText: 'Eliminar',
            }).then((result) => {
                if (result.isConfirmed) {
                    let me = this;
                    axios
                        .post(
                            CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/eliminar-equipo", {
                                idEquipo: param.item.idEquipo
                            }, {
                                headers: {
                                    Authorization: `Bearer ${localStorage.token}`
                                },
                            }
                        )
                        .then(function (response) {
                            let color = response.data.resultado == 1 ? "success" : "error";
                            me.swat(color, response.data.mensaje)
                            me.listarEquiposMedicion();
                        })
                        .catch(function (error) {
                            me.swat('error', 'Algo salió mal!')
                        });
                }
            })
        },
        swat(icon, title) {
            this.$swal.mixin({
                toast: true,
                showConfirmButton: false,
                position: 'bottom-right',
                timer: 3500,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', this.$swal.stopTimer)
                    toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                }
            }).fire({
                icon: icon,
                title: title
            });
        },
        blurTipo() {
            this.computedForm.refs.tipo.validate();
        },
        blurTipoRevision() {
            this.computedForm.refs.revision.validate();
        },
        blurResponsable() {
            this.computedForm.refs.responsable.validate();
        },
        blurActualizarTipo() {
            this.computedActualizarForm.refs.tipo.validate();
        },
        blurActualizarTipoRevision() {
            this.computedActualizarForm.refs.revision.validate();
        },
        blurActualizarResponsable() {
            this.computedActualizarForm.refs.responsable.validate();
        },
    },
    computed: {
        computedForm() {
            return this.$refs.observer;
        },
        computedActualizarForm() {
            return this.$refs.observer2;
        },
        dataSetsEstadistica() {
            return [{
                borderWidth: 3,
                backgroundColor: [
                    '#428bca94',
                    '#b02b2c9e',
                ],
                data: [this.listaEquipos.filter(x => x.fechaProximaRevision < moment().format('YYYY-MM-DD')).length, this.listaEquipos.filter(x => x.fechaProximaRevision >= moment().format('YYYY-MM-DD')).length]
            }]
        }
    },
    watch: {
        modalRegistrarEquipo: function (val) {
            if (val == false) {
                this.resetModalRegistrarEquipo();
            }
        },
        modalActualizarEquipo: function (val) {
            if (val == false) {
                this.resetModalActualizarEquipo();
            }
        }
    },
    mounted() {
        if (this.$store.state.user) {
            let user = this.$store.state.user;
            this.datosSession.idCliente = user.uidClient;
            //this.listarEmpleados();
            this.listarPuestosTrabajo();
            this.listarEquiposMedicion();
        }
    }

}
</script>
